
import React, { useState, useEffect, useCallback } from 'react';
import api from 'lib/api2';
import Filtro from './Filtro';
import qsBuilder from 'lib/qsBuilder';
import Elenco from './Elenco';
import { useNavigate } from "react-router-dom";
import ContextCommand from 'components/ContextCommand';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { removeTags } from 'lib/dati';
import useNotification from 'components/useNotification';

export default function DocumentiElenco({setTitle, setContextCommands}) {
	const navigate = useNavigate();
	const { pushNotification } = useNotification();
	const apiRef = useGridApiRef();
	const [ queryString, setQueryString ] = useState("");
	const [ selectedRows, setSelectedRows ] = useState([]);

	const inviaMail = useCallback ( () => {
		if (!window.confirm("Confermare l'invio delle mail?"))
			return;
		selectedRows.forEach((row) => {
			api.post("/documenti/"+row.idDocumento+"/mail", "").then(d=>{
				pushNotification("Mail inviate", "success");
			}, error=>{
				pushNotification("Errore nell'invio delle mail", "error");
			});
		});
	}, [selectedRows, pushNotification]);

	const inviaFE = useCallback(() => {
		if (!window.confirm("Confermare l'invio dei documenti XML?")) {
			return;
		}
		selectedRows.forEach((row) => {
			if (!row.feInvio) {
				api.post("/fattura/" + row.idDocumento + "/invio", "").then(r => {
					if (r.errorCode) {
						pushNotification("Fattura (ID "+row.idDocumento+") non inviata. Errore: " + removeTags(r.errorDescription), "error");
					} else {
						pushNotification("Fattura (ID "+row.idDocumento+") inviata.", "success");
					}
				});
			} else {
				pushNotification("Fattura (ID "+row.idDocumento+") è già stata inviata.", "warning");
			}
		});
	}, [selectedRows, pushNotification]);
	
	useEffect(() => {
		setTitle('Documenti');
		const contextCommands=[];
		contextCommands.push(<ContextCommand key="1" icon="mail" onClick={ ()=>inviaMail() }>Invia MAIL di cortesia</ContextCommand>);
		contextCommands.push(<ContextCommand key="2" icon="file-upload-outline" onClick={ ()=>inviaFE() }>Invia FE</ContextCommand>);
		contextCommands.push(<ContextCommand key="3" icon="plus" onClick={ ()=>navigate("/documenti/new") }>Inserisci</ContextCommand>);
		setContextCommands(contextCommands);
	}, [setTitle, setContextCommands, inviaMail, inviaFE, navigate]);

	const filtra=useCallback(filtro=>{
		setQueryString(qsBuilder(filtro));
	},[]);
	
	return ( 
		<div style={{marginBottom:50, height:'85vh'}}>
			<Filtro filtra={filtra} />
			<Elenco queryString={queryString} apiRef={apiRef} setSelectedRows={setSelectedRows} />
		</div>
	);
}