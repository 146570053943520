import React, { useState } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Nav from './components/Nav';
import Login from './Login';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useLayoutStyles from './appearance/layoutStyles';
import theme from './appearance/theme';
import { getCookie } from './lib/cookie';
import Routes from './Routes';
import NotificationProvider from './components/NotificationProvider';
import Notification from './components/Notification';
import ReportProvider from './components/Report/ReportProvider';
import Report from './components/Report/Report';
import { BrowserRouter } from "react-router-dom";
import 'dayjs/locale/it';
import 'moment/locale/it';
//import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

function App() {
  if (!getCookie('authstr')) return <Login />;
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <StyledApp />
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

function StyledApp() {
  const classes = useLayoutStyles();
  const [title, setTitle] = useState(false);
  const [contextCommands, setContextCommands] = useState([]);

  return (
    <BrowserRouter>
      <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="it">
        <div className={classes.root}>
          <CssBaseline />
          <Nav title={title} contextCommands={contextCommands} />
          <main className={classes.content}>
            <div className={classes.toolbar} />
            <NotificationProvider>
              <ReportProvider>
                <Report />
                <Notification />
                <Routes setTitle={setTitle} setContextCommands={setContextCommands} />
              </ReportProvider>
            </NotificationProvider>
          </main>
        </div>
      </LocalizationProvider>
    </BrowserRouter>
  );
}

export default App;
